<template>
  <div class="product_box" @click="(e) => { $router.push('/product/detail?productId=' + myinfo.productId) }">
    <div class="pro_info flex-c-s-s">
      <van-image :style="{ opacity: myinfo.releaseFlag !== 1? '0.3' :'1' }" width="100%" height="100%"
                 :src="myinfo.saleIconUrl"
      />
      <template v-if="mytype === 1">
        <div v-if="myinfo.releaseFlag === 2" class="pro_sale_out">
          <van-image width="1.39rem" height="1.39rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/public/sale_out.png"
          />
        </div>
        <div v-if="myinfo.releaseFlag === 3" style="width: 100%;height: 0.64rem;">
          <van-image width="100%" height="0.64rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/presell_pro.png"
          />
        </div>
      </template>
      <div class="pro_name van-multi-ellipsis--l2">{{ myinfo.saleName }}</div>
      <div class="pro_brand_model van-ellipsis">{{ myinfo.proBrand }} | {{ myinfo.proModel }}</div>
      <template v-if="mytype === 1">
        <div v-if="myinfo.activityViewList.length > 0" class="pro_tags_shop">
          <div v-if="myinfo.activityViewList.length <2 && myinfo.paymentDayStatus === 1"
               class="pro_tags_item flex-r-s-c" style="margin-bottom: 0.1rem;"
          >
            <template>
              <div class="tags_bill_item_left">￥</div>
              <div class="tags_bill_item_right">支持分期</div>
            </template>
          </div>
          <div v-for="(activity,activity_index) in myinfo.activityViewList" :key="activity_index"
               class="pro_tags_item flex-r-s-c" style="margin-bottom: 0.1rem;"
          >
            <template v-if="activity.activityViewType === 2 || activity.activityViewType === 3">
              <div class="tags_active_item_left left_minue">{{ activity.activityViewType === 2?'减':'赠' }}</div>
              <div class="tags_active_item_right right_minue van-ellipsis">{{ activity.activityViewName }}</div>
            </template>
            <template v-if="activity.activityViewType === 4">
              <div class="tags_active_item_left left_return">劵</div>
              <div class="tags_active_item_right right_return van-ellipsis">{{ activity.activityViewName }}</div>
            </template>
            <template v-if="activity.activityViewType === 5">
              <div class="tags_active_item_left left_return">返</div>
              <div class="tags_active_item_right right_return van-ellipsis">{{ activity.activityViewName }}</div>
            </template>
          </div>
        </div>
      </template>
      <div class="pro_bottom flex-r-sb-c">
        <div class="pro_money">￥<span
          style="font-size: 0.49rem;"
        >{{ myinfo.showPrice === 0? myinfo.salePrice :'详询客服' }}</span></div>
        <div class="pro_btn" style="margin-right:0.2rem">
          <van-icon v-if="myinfo.showPrice === 0 && myinfo.flashId === null" name="cart" @click.stop="toCart(myinfo)" />
          <van-icon v-if="myinfo.showPrice === 1" name="service" @click.stop="openKeFu" />
        </div>
      </div>
    </div>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
  </div>
</template>

<script>
import kefuBtn from '@/components/kefu_btn'
import { shoppingCart_addUnion } from '@/api/cart'
export default {
  components: { kefuBtn },
  props: {
    info: { type: Object, default: () => { } },
    coming: { type: String, default: 'list' },
    type: { type: String, default: '' }
  },
  data() {
    return {
      myinfo: this.info,
      mycoming: this.coming,
      mytype: this.type
    }
  },
  methods: {
    // 加入购物车
    toCart(item) {
      const parms = {
        companyId: '1000',
        products: [{ productId: item.productId, proNumber: item.minQuantity || 1 }]
      }
      console.log(parms)
      shoppingCart_addUnion(parms).then(res => {
        this.$toast({ message: '加入成功！', duration: 1 * 1000 })
        this.$utils.cartNum(this.$store)
        if (this.mycoming === 'cart') { this.$emit('updata') }
      })
    },
    // 客服
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) }
  }
}
</script>
